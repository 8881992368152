<template>
  <div>
    <el-table v-loading="loading" :data="tableData" style="width: 100%">
      <el-table-column prop="name" label="订单名称">
        <template slot-scope="scope">
          <div class="flex alc">
            <img
              class="icon_vip"
              src="https://www.zizhihelp.com/upload/static/web/vip_record.png"
              alt=""
            />
            <div class="c333 fwb">{{ scope.row.biz }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="payTime" label="订单时间"> </el-table-column>

      <el-table-column prop="payType" label="支付方式"> </el-table-column>

      <el-table-column prop="cost" label="金额（元）">
        <template slot-scope="scope">
          <div class="ce3991d">{{ scope.row.cost }}</div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      hide-on-single-page
      layout="prev, pager, next"
      :total="total"
      @current-change="handleCurrentChange"
      :current-page="form.pageNum"
      :page-size="form.pageSize"
    >
    </el-pagination>
  </div>
</template>

<script>
/* 个人中心-订单记录 */
import { getVipPayRecord } from "@/api/personalCenter";

export default {
  data() {
    return {
      form: {
        pageNum: 1,
        pageSize: 9,
      },

      tableData: [],
      total: 0,
      loading: false,
    };
  },

  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getListData() {
      this.loading = true;
      let params = { ...this.form };
      const { data } = await getVipPayRecord(params);
      this.tableData = data.records;
      this.total = data.total;
      this.loading = false;
    },

    /* 操作 */
    // 分页切换
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getListData();
    },
  },

  created() {
    this.getListData();
  },
};
</script>

<style lang="less" scoped>
.icon_vip {
  width: 42px;
  height: 42px;
  margin-right: 10px;
}

/deep/.el-table__header-wrapper th {
  background-color: #f9fafc !important;
  color: #4f7bff !important;
}
</style>
